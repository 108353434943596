$faculty_names: 'lw', 're', 'we', 'ge', 'ea', 'eb', 'di', 'pp', 'bw', 'fw', 'ps';

.ugent-homepages-faculty-homepage, .ugent-homepages-department-homepage {
  @for $i from 1 through length($faculties) {
    &.section-#{nth($faculty_names, $i)} {
      .content-header-subhome .padding-default {
        background-color: nth($faculties, $i);
      }
      .date-container {
        border-color: nth($faculties, $i);

        &:after,
        &:before,
        span.month {
          border-color: nth($faculties, $i);
        }
      }
    }
  }
}

.border-top {
  border-top: 1px solid $border-color;
}
