// Base font size (body font-size)
$em-base: 17px;
$fz-default: $em-base;
$fz-defaultplus: 18px;
$fz-small: 14px;
$fz-medium: 20px;
$fz-mediumplus: 22px;
$fz-large: 50px;

// Media query settings (used in when() mixin):
$mobile-range: (0, 749px);
$tablet-portrait-range: (750px, 991px);
$desktop-range: (992px, 1440px);

// Colors
$white: #fff;
$black: #202020;
$brand-primary: #1E64C8;
$brand-secondary: #FFD200;
$brand-tertiary: #e9f0fa;
$border-color: #CCCCCC;
$border-color-light: #DDDDDD;
$brand-warning: #fffae5;
$brand-disabled: #B4B4B4;
$brand-faculty1: #F1A42B;
$brand-faculty2: #DC4E28;
$brand-faculty3: #2D8CA8;
$brand-faculty4: #E85E71;
$brand-faculty5: #6F71B9;
$brand-faculty6: #AEB050;
$brand-faculty7: #825491;
$brand-faculty8: #FB7E3A;
$brand-faculty9: #27ABAD;
$brand-faculty10: #BE5190;
$brand-faculty11: #71A860;


// Component colors
$default-text-color: $black;
$dark-text-color: #646464;


// Font families
$default-font-family: 'PannoTextNormal', Arial, sans-serif;
$semibold-font-family: 'PannoTextSemiBold', Arial, sans-serif;
$medium-font-family: 'PannoTextMedium', Arial, sans-serif;
$light-font-family: 'PannoTextLight', Arial, sans-serif;

// Component font families
$body-text-font-family: $light-font-family;
$header-font-family: $medium-font-family;


//numbers
$maxwidth-container: 1280px;
$maxwidth-content: 750px;
$padding-default: 20px;
$padding-default-mobile: 20px;
$padding-large: 60px;
$padding-large-mobile: 30px;
$margin-default-horizontal: $padding-default;
$margin-default-vertical: 30px;//43px;
$margin-default-vertical-mobile: 25px;
$margin-default-vertical-small: 20px;
$margin-default-vertical-small-mobile: 22px;
$transition-duration-default: .15s;
$transition-ease-default: ease-in-out;
$line-height-base: 21/17;
$line-height-medium: 26/20;
$line-height-medium-caps: 20/20;
$line-height-large: 50/50;
$grid-gutter-width: 20px;


// Font awesome font path
$fa-font-path: "../fonts/font-awesome" !default;

// Extra font colors
$discreet-text-color: lighten($default-text-color, 40%);

// Check icon
$halflings-ok: "\e013";
$check-icon: $halflings-ok;
